<template>
  <module-container>
    <!--    <template #icon>-->
    <!--      <i class="wiki-icon wiki-icon-www-index-2 mR12"></i>-->
    <!--    </template>-->
    <template #title>
      <span v-if="title && typeof title === 'string'">{{ title }}</span>
      <span v-else>
        <span
          class="custom-title "
          v-for="(item, index) in title"
          :key="index"
          :class="{ active: titleType[activeType] === item }"
          @click="handleChangeType(index)"
        >
          {{ item }}
        </span>
      </span>
    </template>
    <template #title-right>
      <router-link :to="'/games?type=' + activeType" class="more-btn">
        更多<i class="el-icon el-icon-arrow-right"></i>
      </router-link>
    </template>
    <div class="content">
      <div v-if="type === 'every_day'" class="game-list-container">
        <div v-if="showPrev" class="slide-handler prev" @click="handleSlide('prev')">
          <i class="el-icon el-icon-arrow-left"></i>
        </div>
        <div
          class="slide-container"
          :style="{
            width: slideContainerWidth + 'px',
            transform: `translateX(-${scrollLeft}px)`
          }"
        >
          <div class="game-item" v-for="(game, index) in list" :key="index">
            <div class="swiper-container">
              <swiper
                :auto-destroy="true"
                :auto-update="true"
                :options="{
                  pagination: {
                    el: '.custom-swiper-pagination-' + index,
                    clickable: true
                  }
                }"
                class="swiper-box"
              >
                <swiper-slide v-for="(item, index) in game.screen_shot" :key="index" class="swiper-slide">
                  <a :href="getJumpWikiUrl(game.alias, game.id, game.wiki_created_at != 0)" :title="game.name">
                    <img v-if="item.type === 'image'" v-lazy="item.src" alt="" />
                  </a>
                  <div v-if="item.type === 'video'" class="video">
                    <i class="wiki-icon wiki-icon-video-play" @click="openVideoPlay(item.src)"></i>
                    <img v-lazy="item.cover" alt="" />
                  </div>
                </swiper-slide>
              </swiper>
              <div
                v-show="game.screen_shot.length > 1"
                class="custom-swiper-pagination"
                :class="['custom-swiper-pagination-' + index]"
              ></div>
            </div>
            <a
              :href="getJumpWikiUrl(game.alias, game.id, game.wiki_created_at != 0)"
              :title="game.name"
              target="_blank"
              class="game-box"
            >
              <div class="game-icon">
                <img v-lazy="game.icon" :alt="game.name" />
              </div>
              <div class="game-info">
                <div class="game-name">
                  <span class="name">{{ game.name }}</span>
                  <span class="time"> 上线时间：{{ dayjs(game.pub_at * 1000).format("MM-DD") }} </span>
                </div>
                <div class="game-desc">
                  {{ game.description }}
                </div>
              </div>
            </a>
          </div>
        </div>
        <div v-if="showNext" class="slide-handler next" @click="handleSlide('next')">
          <i class="el-icon el-icon-arrow-right"></i>
        </div>
      </div>
      <div v-else class="timeline-container" :class="{ 'has-slide': type === 'new' }">
        <div class="timeline-slide-btn prev-btn" @click="handleTimelineSlide('prev')">
          <i class="el-icon el-icon-caret-left"></i>
        </div>
        <div style="overflow: hidden;">
          <div
            class="game-list-timeline"
            :style="{
              width: timelineSlideContainerWidth + 'px',
              transform: `translateX(-${timelineScrollLeft}px)`
            }"
          >
            <a
              v-for="(item, index) in list"
              :key="index"
              :href="getJumpWikiUrl(item.alias, item.id, item.wiki_created_at != 0)"
              :title="item.name"
              target="_blank"
              class="item"
            >
              <div class="top-box">
                <div class="date">
                  {{ item.pub_at | dateFormat("MM月DD日") }}
                </div>
                <div class="line">
                  <span class="round"></span>
                </div>
              </div>
              <div class="bottom-box" :class="{ 'is-select': type === 'new' && isSelect(item) }">
                <div class="img">
                  <img v-lazy="item.icon" :alt="item.name" />
                </div>
                <div class="text-box">
                  <div class="title">{{ item.name }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="timeline-slide-btn next-btn" @click="handleTimelineSlide('next')">
          <i class="el-icon el-icon-caret-right"></i>
        </div>
      </div>
      <!-- 视频播放 -->
      <video-player ref="videoPlayer" />
    </div>
  </module-container>
</template>

<script>
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// components
import ModuleContainer from "./moduleContainer";
import VideoPlayer from "./videoPlayer.vue";
// plugins
import { getJumpWikiUrl } from "../../../plugins/util";
// apis
import { apiWwwGameClassList } from "@/api/wwwApi";

const titleType = {
  new: "最近新游",
  future: "每日新游",
  foretell: "情报公开",
  follow: "特别期待",
  every_day: "每日新游"
};
export default {
  name: "newGameContainer",
  mixins: [],
  model: {},
  components: {
    Swiper,
    SwiperSlide,
    ModuleContainer,
    VideoPlayer
  },
  props: {
    type: {
      type: [String, Array],
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      titleType,
      activeType: "",
      scrollLeft: 0,
      slideNum: 0,
      showNext: true,
      showPrev: false,
      timelineSlideNum: 0,
      timelineScrollLeft: 0,
      new_page_no: 1,
      new_page_total: 2,
      newGameList: [],
      future_page_no: 1,
      future_page_total: 2,
      futureGameList: [],
      isInitTimelineData: true
    };
  },
  computed: {
    title() {
      if (typeof this.type === "string") {
        return titleType[this.type];
      } else if (Array.isArray(this.type)) {
        return this.type.map(type => titleType[type]);
      }
      return [];
    },
    list() {
      if (this.activeType) {
        if (this.activeType === "every_day") {
          return this.data["new"] ? this.data["new"].slice(0, 3) : [];
        }
        if (this.activeType === "new") {
          const futureList = this.data["future"] || [];
          const newList = this.data["new"] || [];
          const newGameList = [...this.newGameList];
          const futureGameList = [...this.futureGameList];
          const list = [
            ...[...[...futureGameList.slice(futureList.length)].reverse(), ...[...futureList].reverse()],
            ...[...newList, ...newGameList.slice(newList.length)]
          ];
          return list;
        }
        return this.data[this.activeType] || [];
      }
      return [];
    },
    slideContainerWidth() {
      const num = this.list.length;
      return num * 535 + (num - 1) * 21;
    },
    timelineSlideContainerWidth() {
      const num = this.list.length;
      return num * 108 + (num - 1) * 21;
    }
  },
  watch: {
    type: {
      handler(val) {
        if (typeof val === "string") {
          this.activeType = val;
        } else if (Array.isArray(val)) {
          this.activeType = val[0];
        }
      },
      immediate: true
    },
    list(val) {
      if (this.isInitTimelineData) {
        this.timelineSlideNum = val.findIndex(item => item.id === this.data["new"][0].id) - 3;
        this.timelineScrollLeft = this.timelineSlideNum * (108 + 21);
      }
    }
  },
  created() {},
  beforeMount() {
    if (this.type === "new") {
      this.getNewGameList();
      this.getFutureGameList();
    }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    dayjs,
    getJumpWikiUrl,
    isSelect(item) {
      const selectItemId = this.data["new"].slice(0, 3).map(it => it.id);
      return selectItemId.includes(item.id);
    },
    formatJsonStr(jsonStr) {
      return JSON.parse(jsonStr);
    },
    goPage(item) {
      let url = item.apk_url;
      if (item.alias) {
        url = `//www.${this.wikiDomain}/${item.alias}`;
      }
      return url;
    },
    handleChangeType(index) {
      this.activeType = this.type[index];
    },
    handleSlide(type) {
      if (type === "prev") {
        this.showNext = true;
        this.slideNum -= 2;
        let scrollLeft = this.slideNum * (535 + 21);
        if (scrollLeft <= 0) {
          this.showPrev = false;
          scrollLeft = 0;
        }

        this.scrollLeft = scrollLeft;
      } else {
        this.showPrev = true;
        this.slideNum += 2;
        let scrollLeft = this.slideNum * (535 + 21);
        if (scrollLeft >= this.slideContainerWidth - (1300 - 40)) {
          this.showNext = false;
          scrollLeft = this.slideContainerWidth - (1300 - 40);
        }

        this.scrollLeft = scrollLeft;
      }
    },
    openVideoPlay(video) {
      this.$refs.videoPlayer.url = video;
      this.$refs.videoPlayer.visible = true;
    },
    // 获取更多新游
    async getNewGameList() {
      try {
        if (this.new_page_no > this.new_page_total) {
          return;
        }
        const { code, data, meta } = await apiWwwGameClassList({
          page_no: this.new_page_no,
          limit: 20,
          type: "new"
        });
        if (code === 0) {
          this.newGameList = this.newGameList.concat(data);
          this.new_page_total = meta.pagination.page_total;
          this.new_page_no += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取更多即将上线
    async getFutureGameList() {
      try {
        if (this.future_page_no > this.future_page_total) {
          return;
        }
        const { code, data, meta } = await apiWwwGameClassList({
          page_no: this.future_page_no,
          limit: 20,
          type: "future"
        });
        if (code === 0) {
          this.futureGameList = this.futureGameList.concat(data);
          this.future_page_total = meta.pagination.page_total;
          this.future_page_no += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleTimelineSlide(type) {
      this.isInitTimelineData = false;
      if (type === "prev") {
        // 快到头时
        if (this.timelineSlideNum <= 3) {
          this.getFutureGameList();
        }
        if (this.timelineSlideNum <= 0) {
          this.timelineSlideNum = 0;
          return;
        }

        this.timelineSlideNum -= 3;
        let timelineScrollLeft = this.timelineSlideNum * (108 + 21);
        if (timelineScrollLeft <= 0) {
          timelineScrollLeft = 0;
        }

        this.timelineScrollLeft = timelineScrollLeft;
      } else {
        if (this.timelineSlideNum >= this.list.length - 9) {
          this.timelineSlideNum = this.list.length - 9;
          return;
        }
        // 快滚动完成时
        if (this.timelineSlideNum >= this.list.length - 15) {
          this.getNewGameList();
        }
        this.timelineSlideNum += 3;
        let timelineScrollLeft = this.timelineSlideNum * (108 + 21);
        if (timelineScrollLeft >= this.timelineSlideContainerWidth - (1300 - 164)) {
          timelineScrollLeft = this.timelineSlideContainerWidth - (1300 - 164);
        }
        this.timelineScrollLeft = timelineScrollLeft;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.custom-title {
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  line-height: 27px;
  margin-right: 53px;
  cursor: pointer;
  &.active {
    color: #171717;
  }
}

.game-list-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  .slide-container {
    display: flex;
    gap: 21px;
    transition: all 0.3s;
    .game-item {
      position: relative;
      flex-shrink: 0;
      width: 535px;
      height: 387px;
      border: 1px solid #e3e3e3;
      .swiper-container {
        width: 100%;
        height: 100%;
        --swiper-theme-color: #7175a1;
        .swiper-slide {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .video {
            width: 100%;
            height: 100%;
            position: relative;
            .wiki-icon {
              position: absolute;
              display: inline-block;
              width: 60px;
              height: 60px;
              top: 121px;
              left: 236px;
              cursor: pointer;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .custom-swiper-pagination {
          position: absolute;
          bottom: 90px;
          width: 100%;
          z-index: 1;
          text-align: center;
          /deep/ .swiper-pagination-bullet {
            margin-right: 4px;
          }
        }
      }
      .game-box {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 87px;
        padding: 11px 12px;
        display: flex;
        backdrop-filter: blur(20px);
        background-color: rgba(0, 0, 0, 0.2);
        .game-icon {
          flex-shrink: 0;
          width: 64px;
          height: 64px;
          margin-right: 10px;
          border-radius: 4px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            transition: all 0.2s;
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        .game-info {
          flex: 1;
          width: 100%;
          .game-name {
            height: 14px;
            display: flex;
            margin-bottom: 12px;
            justify-content: space-between;
            align-items: center;
            .name {
              flex: 1;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              line-height: 21px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .time {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
            }
          }
          .game-desc {
            height: 36px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.9);
            line-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  .slide-handler {
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 387px;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    .el-icon {
      font-size: 23px;
      color: #fff;
    }

    &.prev {
      top: 0;
      left: 0;
    }
    &.next {
      top: 0;
      right: 0;
    }
  }
}

.timeline-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  &.has-slide {
    padding: 0 62px;
    .timeline-slide-btn {
      display: flex;
    }
  }
  .timeline-slide-btn {
    display: none;
    position: absolute;
    width: 32px;
    height: 155px;
    background: #787980;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: #86878f;
    }
  }
  .prev-btn {
    left: 0;
    top: 25px;
  }
  .next-btn {
    right: 0;
    top: 25px;
  }
}
.game-list-timeline {
  --gap-width: 21px;
  display: flex;
  gap: var(--gap-width);
  transition: all 0.3s;
  .item {
    flex-shrink: 0;
    .top-box {
      text-align: center;
      .date {
        font-size: 11px;
        color: #333;
        font-weight: 500;
        margin-bottom: 3px;
      }
      .line {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
          width: calc(100% + var(--gap-width));
          height: 1px;
          background: #dddddd;
          position: absolute;
          left: 0;
          top: 10px;
        }
        .round {
          width: 20px;
          height: 20px;
          background: #d8d8d8;
          border-radius: 50%;
          border: 6px solid #fff;
          position: relative;
          z-index: 1;
        }
      }
    }

    .bottom-box {
      padding: 7px 8px;
      border: 1px solid transparent;
      &.is-select {
        background: rgba(0, 175, 232, 0.1);
        border-radius: 4px;
        border: 1px solid #00afe8;
      }
      .img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 90px;
          height: 90px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .text-box {
        margin-top: 12px;
        width: 90px;
        overflow: hidden;
        margin-bottom: 7px;
        .title {
          .ellipsis;
          width: 100%;
          font-size: 14px;
          color: #212121;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}
</style>

<template>
  <a
    :href="getJumpWikiDetailUrl(item.game_alias || 'www', item.game_id, item.id)"
    :target="device.desktop ? '_blank' : '_self'"
    :title="item.game.name + item.title"
    class="item"
  >
    <div class="cover">
      <img v-lazy="handleImgSize(item.thumb.split(',')[0], 'icon')" :alt="item.game.name + item.title" />
    </div>

    <div class="item-r">
      <div class="title">{{ item.title }}</div>
      <div class="desc">{{ item.summary }}</div>
      <div class="bottom-row">
        <div v-if="false" class="user">
          <img v-lazy="handleImgSize(item.user.avatar, 'icon')" :alt="item.title" />
          <span>{{ item.user.username }}</span>
        </div>
        <div class="row-r">
          {{ $dayjs.unix(item.created_at).format("YYYY-MM-DD") }}
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import { getJumpWikiDetailUrl } from "../../../plugins/util";
export default {
  name: "newsItem",
  props: {
    item: {
      default: () => {}
    }
  },
  methods: {
    getJumpWikiDetailUrl
  }
};
</script>

<style scoped lang="less">
.item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 84px;
  overflow: hidden;
  .cover {
    height: 84px;
    width: 140px;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .item-r {
    width: calc(100% - 118px);
    padding: 0 8px 4px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 14px;
      font-weight: 500;
      color: #212121;
      line-height: 20px;
      overflow: hidden;
      height: 40px;
      text-align: justify;
    }

    .desc {
      font-size: 12px;
      color: #999;
      line-height: 16px;
      height: 32px;
      overflow: hidden;
      display: none;
    }
    .bottom-row {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #999;

      .user {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
          border: 1px solid #909090;
          margin-right: 8px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>

<template>
  <div class="index-page">
    <div class="l-wrapper">
      <!-- 顶部banner -->
      <banner-container :list="moduleList.bannerList" />
      <!-- 热门wiki -->
      <hot-wiki-container :list="moduleList.hotWikiList" />
      <!-- 最新wiki -->
      <new-wiki-container :list="moduleList.newWiki" />
      <!-- 每日游戏 -->
      <new-game-container :data="moduleList.indexGame" type="every_day" />
      <!-- 最近新游 -->
      <new-game-container :data="moduleList.indexGame" type="new" />
      <!-- 游戏推荐 -->
      <game-recommend :list="moduleList.contentRecommend"></game-recommend>
      <!-- 游戏情报 -->
      <news-container :list="moduleList.newsList" />
      <!-- 特别期待, 情报公开 -->
      <new-game-container :data="moduleList.indexGame" :type="['foretell', 'follow']" />
      <!-- k站推荐 -->
      <!-- <game-recommend-container :list="moduleList.recommendGameList" /> -->
      <!-- 玩家社区 -->
      <index-content-list :list="contentList" />
      <!-- 更多内容 -->
      <more-content />
      <!-- 友情链接 -->
      <friend-links />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// components
import BannerContainer from "./components/bannerContainer";
import HotWikiContainer from "./components/hotWikiContainer";
import NewWikiContainer from "./components/newWikiContainer";
import NewsContainer from "./components/newsContainer";
// import DescContainer from "./components/descContainer";
// import ModuleContainer from "./components/moduleContainer";
import NewGameContainer from "./components/newGameContainer";
// import SideContainer from "../../views/www/components/sideContainer";
// import SvgIcon from "../../components/SvgIcon/svgIcon";
// import WikiLib from "./components/wikiLib";
// import VideoContainer from "@/views/www/components/videoContainer.vue";
// import RightTopContainer from "@/views/www/components/rightTopContainer.vue";
import IndexContentList from "@/views/www/components/indexContentList.vue";
import MoreContent from "@/views/www/components/moreContent.vue";
import GameRecommend from "./components/gameRecommend";
// import GameRecommendContainer from "@/views/www/components/gameRecommendContainer.vue";
import FriendLinks from "./components/friendLinks.vue";
// apis
import { apiWwwIndex } from "@/api/wwwApi";
// plugins
import eventBus from "../../plugins/eventBus";
// mixins
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

// 格式化模块数据
function handleModuleList(data) {
  const moduleList = {
    bannerList: [], // 轮播模块
    adList: [], // 广告模块
    newGameList: [], // 新游速递
    manualList: [], // wiki手册
    hotWikiList: [], // 热门wiki
    newsList: [], // 情报
    newWiki: [], // 最新wiki
    wikiLib: [], // wiki库
    videoList: [], // 游戏视频
    indexGame: {}, // 新游专区
    contentRecommend: [] // 游戏推荐
    // recommendGameList: [] // 首页游戏推荐
  };
  if (data) {
    moduleList.wikiLib = data.wiki_lib;
    moduleList.newWiki = data.new_wiki;
    moduleList.newsList = data.news_list;
    moduleList.videoList = data.video_list;
    moduleList.indexGame = data.index_game;
    moduleList.recommendGameList = data.recommend_game;
    moduleList.contentRecommend = data.content_recommend;
    data.module_list.forEach(item => {
      if (item.module.type == 1) {
        moduleList.bannerList = item.list;
      } else if (item.module.type == 11) {
        moduleList.adList = item.list;
      } else if (item.module.type == 12) {
        moduleList.newGameList = item.list;
      } else if (item.module.type == 13) {
        moduleList.manualList = item.list;
      } else if (item.module.type == 10) {
        moduleList.hotWikiList = item.list;
      }
    });
  }
  return moduleList;
}
export default {
  name: "index",
  mixins: [mergeAsyncDataMixin],
  props: {},
  model: {},
  components: {
    GameRecommend,
    // GameRecommendContainer,
    MoreContent,
    IndexContentList,
    // RightTopContainer,
    // VideoContainer,
    // WikiLib,
    NewGameContainer,
    // ModuleContainer,
    // DescContainer,
    NewsContainer,
    NewWikiContainer,
    HotWikiContainer,
    BannerContainer,
    // SideContainer,
    // SvgIcon,
    FriendLinks
  },
  data() {
    return {
      contentList: {},
      moduleList: handleModuleList(),
      // 服务端获取moduleList
      isSSRGetData: false
    };
  },
  async asyncData({ $apis }) {
    try {
      console.log("asyncData start");
      // 数据预取
      const res = await Promise.all([
        $apis.apiWwwIndex(),
        $apis.apiWwwContentPageList({ type: 1, limit: 6 }),
        $apis.apiWwwContentPageList({ type: 4, limit: 6 })
        // $apis.apiWwwContentPageList({ type: 15, limit: 4 })
      ]);
      const indexData = res && res[0] ? res[0].data : null;
      const typeList1 = res && res[1] ? res[1].data : [];
      const typeList4 = res && res[2] ? res[2].data : [];
      // const typeList15= res && res[3] ? res[3].data : []

      const moduleList = handleModuleList(indexData);

      const contentList = {
        1: typeList1,
        4: typeList4
        // 15: typeList15
      };
      console.log("asyncData end");
      return {
        moduleList,
        isSSRGetData: true,
        contentList
      };
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser", "redPoint", "gameInfo"])
  },
  watch: {},
  created() {},
  beforeMount() {
    // 判断服务端已获取数据
    if (!this.isSSRGetData) {
      this.getData();
    }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    onShowLogin() {
      eventBus.$emit("onShowLogin");
    },
    getData() {
      apiWwwIndex().then(({ data }) => {
        this.moduleList = handleModuleList(data);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.index-page {
  background: #fff;
  padding: 20px;
  border-radius: 6px;

  .l-wrapper {
    width: 100%;
    min-height: 500px;
  }

  .r-wrapper {
    width: 630px;
    min-height: 500px;
    display: none;
  }
}

.ad-container {
  .item {
    width: 100%;
    height: 90px;
    display: block;

    & + .item {
      margin-top: 12px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

.wap-index-page {
  .l-wrapper {
    width: 100%;
  }

  .r-wrapper {
    display: none;
  }
}

.pc-index-page {
  margin: auto;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
}

.manual-container {
  .item {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    padding: 0 12px;
    font-size: 13px;
    color: #666;

    & + .item {
      margin-top: 8px;
    }
  }
}

.app-bar {
  .app-bar-content {
    background: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }
}

.wap-footer {
  padding: 8px;
  background: #f5f5f5;
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  a {
    color: inherit;
  }
}
.news-video-box {
  display: flex;
  align-items: stretch;
  .row-item-1 {
    flex: 1;
    overflow: hidden;
  }
  .row-item-2 {
    width: 380px;
    padding-left: 28px;
    margin-left: 20px;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: calc(100% - 90px);
      width: 1px;
      top: 44px;
      left: 0;
      background: #e3e3e3;
    }
  }
}
</style>

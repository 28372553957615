<template>
  <module-container title="玩家社区">
    <div class="content-list">
      <template>
        <a
          v-for="item in list[4]"
          class="item"
          :href="getJumpWikiDetailUrl(item.game_alias, item.game_id, item.id)"
          :key="item.id"
          target="_blank"
        >
          <div class="img">
            <img v-lazy="item.game.icon" :alt="item.game.name" />
          </div>
          <div class="r-wrapper">
            <div class="row1">
              玩家{{
                item.updated_at > item.created_at ? item.update_user.username : item.user.username
              }}&nbsp;&nbsp;在&nbsp;&nbsp;{{ item.game.name }}
            </div>
            <div class="row2">{{ item.updated_at > item.created_at ? "更新" : "发布" }}帖子：{{ item.title }}</div>
            <div class="row3">
              {{ item.summary }}
            </div>
          </div>
        </a>
      </template>
      <template>
        <a
          v-for="item in list[1]"
          class="item"
          :href="getJumpWikiDetailUrl(item.game_alias, item.game_id, item.id)"
          :key="item.id"
          target="_blank"
        >
          <div class="img">
            <img v-lazy="item.game.icon" :alt="item.game.name" />
          </div>
          <div class="r-wrapper">
            <div class="row1">
              玩家{{
                item.updated_at > item.created_at ? item.update_user.username : item.user.username
              }}&nbsp;&nbsp;在&nbsp;&nbsp;{{ item.game.name }}
            </div>
            <div class="row2">{{ item.updated_at > item.created_at ? "更新" : "发布" }}词条：{{ item.title }}</div>
            <div class="row3">
              {{ item.summary }}
            </div>
          </div>
        </a>
      </template>
    </div>
  </module-container>
</template>
<script>
import ModuleContainer from "@/views/www/components/moduleContainer.vue";
import { getJumpWikiDetailUrl } from "@/plugins/util";

export default {
  name: "indexContentList",
  components: {
    ModuleContainer
  },
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    getJumpWikiDetailUrl
  }
};
</script>
<style scoped lang="less">
.content-list {
  display: grid;
  grid-gap: 28px 28px;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
  .item {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    .img {
      width: 68px;
      height: 68px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .r-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      overflow: hidden;
      padding: 2px 0;
      padding-left: 13px;
      .row1 {
        font-size: 12px;
        color: #999;
      }
      .row2 {
        .ellipsis;
        color: #212121;
        font-size: 14px;
        margin-bottom: 4px;
      }
      .row3 {
        .ellipsis;
        color: #666;
        font-size: 12px;
      }
    }
  }
}
</style>

<template>
  <module-container title="更多内容">
    <div class="link-group">
      <a href="//www.gamekee.com/u" target="_blank">游戏学院</a>
      <a href="//www.gamekee.com/wiki" target="_blank">wiki指南</a>
      <a href="//space.bilibili.com/334551777" target="_blank">GameKee游戏姬</a>
      <a href="//www.gamekee.com/76934.html" target="_blank">申请wiki</a>
    </div>
  </module-container>
</template>
<script>
import ModuleContainer from "./moduleContainer.vue";

export default {
  name: "moreContent",
  components: { ModuleContainer },
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.link-group {
  display: flex;
  a {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #cecece;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
}
</style>

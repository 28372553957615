<template>
  <module-container title="游戏情报">
    <!--    <template #icon>-->
    <!--      <i class="wiki-icon wiki-icon-www-index-6 mR12"></i>-->
    <!--    </template>-->
    <template #title-right>
      <router-link to="/news" class="more-btn"> 更多<i class="el-icon el-icon-arrow-right"></i> </router-link>
    </template>
    <div class="list-box">
      <template v-for="(item, index) in list">
        <news-item :item="item" :key="index" v-if="index < 15" />
      </template>
    </div>
  </module-container>
</template>

<script>
import { mapGetters } from "vuex";
// componets
import ModuleContainer from "./moduleContainer";
import SvgIcon from "../../../components/SvgIcon/svgIcon";
import ScrollComp from "../../../components/Scroll";
import NewsItem from "@/views/www/components/newsItem.vue";
// api
import { apiWwwNewsList } from "../../../api/wwwApi";

export default {
  name: "newsContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {
    NewsItem,
    ScrollComp,
    SvgIcon,
    ModuleContainer
  },
  data() {
    return {
      pagination: {
        limit: 10,
        page_no: 1,
        page_total: 2,
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser"])
  },
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.list-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}
</style>

<template>
  <!-- 视频播放 -->
  <el-dialog :visible.sync="visible" width="900px" append-to-body center custom-class="video-player">
    <template v-if="url">
      <video width="100%" autoplay controls :src="url"></video>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: "videoPlayer",
  data() {
    return {
      visible: false,
      url: ""
    };
  }
};
</script>
<style scoped lang="less">
/deep/ .video-player {
  background: transparent;
  box-shadow: none;
  .el-dialog__header {
    .el-dialog__headerbtn {
      top: 10px;
      right: 5px;
      .el-icon-close {
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
</style>

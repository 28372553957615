<template>
  <div :class="['module-container', classBase + 'module-container']">
    <div v-if="title || $slots.title" class="module-title">
      <div class="t-l">
        <slot name="icon"></slot>
        <slot v-if="$slots.title" name="title"></slot>
        <span v-else>{{ title }}</span>
      </div>
      <div class="t-r">
        <slot name="title-right"></slot>
      </div>
    </div>
    <div class="module-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "moduleContainer",
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  model: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.module-container {
  margin-bottom: 54px;
}

.module-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 14px;

  .t-l {
    font-size: 16px;
    color: #171717;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .t-r {
    /deep/ .more-btn {
      color: #3d3d3d;
      font-size: 13px;
      display: flex;
      align-items: center;
      line-height: 1;
      .el-icon {
        border: 1px solid #3d3d3d;
        border-radius: 4px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        margin-left: 2px;
      }
    }
  }
}

.module-content {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>

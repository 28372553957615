<template>
  <module-container title="友情链接">
    <div class="friend-links">
      <a class="link" href="https://www.9game.cn/" target="_blank">手游</a>
      <el-divider direction="vertical" />
      <a class="link" href="https://www.gameres.com/" target="_blank">
        GameRes
      </a>
      <el-divider direction="vertical" />
      <a class="link" href="https://www.youxituoluo.com/" target="_blank">
        游戏陀螺
      </a>
      <el-divider direction="vertical" />
      <a class="link" href="https://www.ourplay.net/" target="_blank">
        OurPlay加速器
      </a>
      <!-- <el-divider direction="vertical" />
      <a class="link" href="https://g.gamekee.com/" target="_blank">
        GG攻略
      </a> -->
      <el-divider direction="vertical" />
      <a class="link" href="https://www.iyingdi.com/" target="_blank">
        旅法师营地
      </a>
      <el-divider direction="vertical" />
      <a class="link" href="https://mumu.163.com/fab/gamekee/" target="_blank">
        MuMu模拟器
      </a>
      <el-divider direction="vertical" />
      <a class="link" href="https://www.gamekee.com/u/582832.html" target="_blank">
        奇游加速器
      </a>
    </div>
  </module-container>
</template>

<script>
import ModuleContainer from "./moduleContainer.vue";
export default {
  name: "friend-links",
  components: {
    ModuleContainer
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.friend-links {
  width: 100%;
  .link {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    line-height: 21px;
    margin: 0 28px;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>

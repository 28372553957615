<template>
  <div class="scroll-comp">
    <slot />
    <div class="loading-text" v-if="loadStatus">
      <template v-if="loadStatus == 'loading'"> <i class="el-icon-loading"></i><span>加载中...</span> </template>
      <template v-if="loadStatus == 'end'">
        <span>没有更多数据了~</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "scrollComp",
  props: {
    // 触发分页后的方法
    pageLoad: {
      type: Function,
      default: null
    },
    // 是否需要恢复滚动位置，用于页面被缓存的情况，返回页面恢复位置
    restorePosition: {
      type: Boolean,
      default: true
    }
  },
  model: {},
  components: {},
  data() {
    return {
      // 页面滚动的距离
      scrollTop1: 0,
      // 窗口高度
      winHeight: 0,
      // 页面总高度
      pageHeight: 0,
      // 距离底部多少时触发分页加载
      scrollBottomLen: 300,
      // 数据请求状态  ''=>未请求，loading=>请求中， end=>已加载完所有分页
      loadStatus: ""
    };
  },
  computed: {},
  created() {
    // window.addEventListener('scroll', this.windowScroll);
  },
  beforeMount() {
    window.addEventListener("scroll", this.windowScroll);
  },
  // 返回页面时恢复滚动位置
  activated() {
    setTimeout(() => {
      if (this.restorePosition) {
        window.scrollTo({
          top: this.scrollTop1
        });
      }
      window.addEventListener("scroll", this.windowScroll);
    }, 200);
  },
  deactivated() {
    window.removeEventListener("scroll", this.windowScroll, false);
  },
  // 页面离开时记录滚动位置
  beforeRouteLeave(to, from, next) {
    this.scrollTop1 = window.document.documentElement.scrollTop || window.document.body.scrollTop;
    next();
  },
  methods: {
    windowScroll(e) {
      this.scrollTop1 = window.document.documentElement.scrollTop || window.document.body.scrollTop;
      this.pageHeight = window.document.documentElement.offsetHeight || window.document.body.offsetHeight;
      this.winHeight = window.document.documentElement.clientHeight;

      if (this.pageHeight < this.winHeight) return;
      if (this.pageHeight < this.winHeight + this.scrollTop1 + this.scrollBottomLen) {
        // 达到触发分页条件,判断是否有分页方法
        if (this.pageLoad && !this.loadStatus) {
          this.pageLoad();
        }
      }
    }
  },
  watch: {},
  mounted() {},
  // 页面注销时销毁事件
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll, false);
  },
  updated() {},
  destroyed() {}
};
</script>

<style scoped lang="less">
.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  color: #999;

  i {
    margin-right: 10px;
  }
}
</style>

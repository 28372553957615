<template>
  <module-container title="热门wiki">
    <template #title-right v-if="!hideMore">
      <router-link to="/wikis" class="more-btn"> 更多<i class="el-icon el-icon-arrow-right"></i> </router-link>
    </template>
    <div class="item-group">
      <template v-for="(item, index) in list">
        <a
          v-if="index < 12"
          :key="index"
          :title="item.title"
          :href="getJumpWikiUrl(item.alias, item.game_id, true)"
          target="_blank"
          class="item"
        >
          <div class="img">
            <img :src="item.cover" :alt="item.game_name" loading="lazy" />
          </div>
          <div class="text-box">
            <div class="title">{{ item.game_name }}</div>
            <div class="tag">wiki热度： {{ item.hot }}</div>
          </div>
        </a>
      </template>
    </div>
  </module-container>
</template>

<script>
// import SvgIcon from "../../../components/SvgIcon/svgIcon";
import ModuleContainer from "./moduleContainer";
import { getJumpWikiUrl } from "../../../plugins/util";

export default {
  name: "hotWikiContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    hideMore: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {
    ModuleContainer
    // SvgIcon
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getJumpWikiUrl
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.item-group {
  display: grid;
  grid-gap: 32px 16px;
  grid-template-columns: repeat(6, 1fr);

  &.item-group-8 {
    grid-template-columns: repeat(8, 1fr);
  }

  .item {
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .img {
      width: 100%;
      height: 260px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 260px;
        object-fit: cover;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      line-height: 1;
      height: 44px;
      padding-top: 10px;

      .title {
        .ellipsis;
        width: 100%;
        color: #212121;
        font-weight: 500;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
      }
      .tag {
        font-size: 12px;
        color: #666;
      }
    }
  }
}
</style>

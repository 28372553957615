<template>
  <module-container title="游戏推荐">
    <div class="game-recommend">
      <div class="game-video">
        <Transition name="fade">
          <div v-if="currentGame && currentGame.content" class="video" :key="currentGame.content.id">
            <video
              muted
              disablePictureInPicture
              controlslist=" nodownload noremoteplayback noplaybackrate"
              ref="videoPlayer"
              :controls="!video.paused"
              :src="currentGame.content.video.video"
              :poster="currentGame.content.video.cover"
              @pause="onPauseVideo"
              @play="onPlayVideo"
              @fullscreenchange="onFullscreenVideo"
            ></video>
            <i v-if="video.paused" class="play-icon wiki-icon wiki-icon-video-play" @click="handlePlayVideo"></i>
            <div class="select-btn prev" @click="selectVideo('prev')">
              <i class="el-icon el-icon-arrow-left"></i>
            </div>
            <div class="select-btn next" @click="selectVideo('next')">
              <i class="el-icon el-icon-arrow-right"></i>
            </div>
          </div>
        </Transition>
      </div>
      <div class="recommend-list">
        <div
          class="recommend-item"
          v-for="(item, index) in list"
          :key="index"
          :class="{ active: activeIndex == index }"
        >
          <a :href="getJumpWikiDetailUrl(item.game.alias, item.game.id, item.content.id)" target="_blank">
            <div class="cover">
              <img :src="item.content.video.cover" alt="" />
            </div>
            <div class="title">{{ item.content.title }}</div>
          </a>

          <div class="info">
            <span :title="item.game.name">
              <a
                :href="getJumpWikiUrl(item.game.alias, item.game.id, item.game.wiki_created_at != 0)"
                class="game-name"
                target="_blank"
              >
                {{ item.game.name }}
              </a>
            </span>
            <span>
              <i class="wiki-icon wiki-icon-play-num"></i>
              {{ item.content.view_count }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </module-container>
</template>

<script>
// components
import ModuleContainer from "./moduleContainer";
// plugins
import { getJumpWikiDetailUrl, getJumpWikiUrl } from "@/plugins/util";

export default {
  name: "GameRecommend",
  components: {
    ModuleContainer
  },

  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      timer: null,
      activeIndex: 0,
      video: {
        paused: false
      },
      isAutoPlay: false
    };
  },
  computed: {
    currentGame() {
      return this.list ? this.list[this.activeIndex] : null;
    }
  },
  mounted() {
    // console.log(this.list);
    // 自动选择下一条视频
    // this.autoSelectVideo();
    // 监听视频是否进入视口
    this.observerVideoIntersection();
  },
  beforeDestroy() {
    if (this.timer) window.clearInterval(this.timer);
  },

  methods: {
    getJumpWikiUrl,
    getJumpWikiDetailUrl,
    observerVideoIntersection() {
      const videoPlayer = this.$refs.videoPlayer;
      const observer = new IntersectionObserver(entries => {
        // 当目标元素进入或离开视口时，会触发回调函数
        entries.forEach(entry => {
          // 如果目标元素进入视口
          if (entry.isIntersecting) {
            // console.log("Element is in viewport");
            // 在这里可以执行进入视口后的操作
            videoPlayer.setAttribute("autoplay", true);
            this.isAutoPlay = true;
            // 自动选择下一条视频
            this.autoSelectVideo();
          } else {
            // 如果目标元素离开视口
            // console.log("Element has left viewport");
            // 在这里可以执行离开视口后的操作
            videoPlayer.setAttribute("autoplay", false);
            this.isAutoPlay = false;
            if (this.timer) window.clearInterval(this.timer);
          }
        });
      });

      // 开始观察目标元素
      observer.observe(videoPlayer);
    },
    autoSelectVideo() {
      if (this.timer) window.clearInterval(this.timer);
      this.timer = window.setInterval(() => {
        let index = this.activeIndex;
        index += 1;
        if (index >= this.list.length) {
          this.activeIndex = 0;
        } else {
          this.activeIndex = index;
        }
        // this.video.paused = false;
        this.$nextTick(() => {
          if (this.isAutoPlay) {
            this.$refs.videoPlayer.setAttribute("autoplay", true);
          } else {
            this.$refs.videoPlayer.setAttribute("autoplay", false);
          }
        });
      }, 8000);
    },
    // 选中视频
    selectVideo(type) {
      if (type == "prev") {
        this.activeIndex = this.activeIndex - 1 >= 0 ? this.activeIndex - 1 : 0;
      } else {
        this.activeIndex = this.activeIndex + 1 < this.list.length ? this.activeIndex + 1 : 0;
      }
      this.$nextTick(() => {
        if (this.isAutoPlay) {
          this.$refs.videoPlayer.setAttribute("autoplay", true);
        } else {
          this.$refs.videoPlayer.setAttribute("autoplay", false);
        }
      });
    },
    // 播放视频
    handlePlayVideo() {
      this.$refs.videoPlayer.play();
    },
    // 播放暂停
    onPauseVideo() {
      this.video.paused = true;
      // 暂停不再切换
      if (this.timer) window.clearInterval(this.timer);
    },
    onPlayVideo() {
      this.video.paused = false;
      // 播放切换
      this.autoSelectVideo();
    },
    onFullscreenVideo() {
      if (document.fullscreenElement) {
        // 全屏不再切换
        if (this.timer) window.clearInterval(this.timer);
      } else {
        // 退出全屏切换
        this.autoSelectVideo();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.game-recommend {
  width: 100%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  .game-video {
    width: 670px;
    height: 380px;
    position: relative;
    background: url(../../../assets//images/index-recommend-bg.png) no-repeat;
    background-size: cover;
    &:hover {
      .select-btn {
        display: block;
      }
    }
    .video {
      width: 670px;
      height: 380px;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .play-icon {
      display: inline-block;
      width: 57px;
      height: 57px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .select-btn {
      display: none;
      position: absolute;
      width: 40px;
      height: 40px;
      font-size: 40px;
      color: #fff;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        opacity: 0.8;
        transform: scale(1.01);
      }
      &.prev {
        top: 160px;
        left: 20px;
      }

      &.next {
        top: 160px;
        right: 20px;
      }
    }
  }

  .recommend-list {
    width: 550px;
    height: 340px;
    box-sizing: content-box;
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    background: url(../../../assets//images/index-recommend-bg.png) no-repeat;
    background-size: cover;
    .recommend-item {
      width: 100%;
      height: 160px;
      &.active {
        .cover {
          border: 2px solid #ffffff;
        }
      }
      .cover {
        width: 167px;
        height: 94px;
        border-radius: 6px;
        margin-bottom: 7px;
        cursor: pointer;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .title {
        width: 167px;
        height: 36px;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        margin-bottom: 6px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .info {
        width: 167px;
        height: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #bfbfbf;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        .game-name {
          color: #bfbfbf;
        }
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:first-child {
            flex: 1;
          }
          .wiki-icon {
            display: inline-block;
            width: 11px;
            height: 10px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <module-container title="最新wiki">
    <!--    <template #icon>-->
    <!--      <i class="wiki-icon wiki-icon-www-index-3 mR12"></i>-->
    <!--    </template>-->
    <template #title-right v-if="!hideMore">
      <router-link to="/wikis" class="more-btn"> 更多<i class="el-icon el-icon-arrow-right"></i> </router-link>
    </template>
    <div class="item-group">
      <template v-for="(item, index) in list">
        <a
          v-if="index < 10"
          :key="index"
          :href="getJumpWikiUrl(item.alias, item.id, item.wiki_created_at != 0)"
          :title="item.name"
          target="_blank"
          class="item"
        >
          <div class="top-box">
            <div class="date">
              {{ item.wiki_created_at | dateFormat("MM月DD日") }}
            </div>
            <div class="line">
              <span class="round"></span>
            </div>
          </div>
          <div class="bottom-box">
            <div class="img">
              <img v-lazy="handleImgSize(item.icon)" :alt="item.name" />
            </div>
            <div class="text-box">
              <div class="title">{{ item.name }}</div>
            </div>
          </div>
        </a>
      </template>
    </div>
  </module-container>
</template>

<script>
import ModuleContainer from "./moduleContainer";
import SvgIcon from "../../../components/SvgIcon/svgIcon";
import { getJumpWikiUrl } from "../../../plugins/util";

export default {
  name: "newWikiContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    hideMore: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {
    ModuleContainer,
    SvgIcon
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getJumpWikiUrl,
    onScroll(type) {
      let scrollLeft = this.$refs.scrollBox.scrollLeft;
      this.$refs.scrollBox.scrollTo({
        left: scrollLeft + type * 600,
        behavior: "smooth"
      });
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.item-group {
  --gap-width: 39px;
  display: grid;
  grid-gap: var(--gap-width);
  grid-template-columns: repeat(10, 1fr);
  overflow: hidden;
  .item {
    .top-box {
      text-align: center;
      .date {
        font-size: 11px;
        color: #333;
        font-weight: 500;
        margin-bottom: 3px;
      }
      .line {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
          width: calc(100% + var(--gap-width));
          height: 1px;
          background: #dddddd;
          position: absolute;
          left: 0;
          top: 10px;
        }
        .round {
          width: 20px;
          height: 20px;
          background: #d8d8d8;
          border-radius: 50%;
          border: 6px solid #fff;
          position: relative;
          z-index: 1;
        }
      }
    }

    .bottom-box {
      padding: 7px 0;
      .img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 90px;
          height: 90px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .text-box {
        margin-top: 12px;
        width: 90px;
        overflow: hidden;
        margin-bottom: 7px;
        .title {
          .ellipsis;
          width: 100%;
          font-size: 14px;
          color: #212121;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}
</style>
